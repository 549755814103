// eslint-disable-next-line import/extensions

const { version } = require('../package.json');

const constant = {
  client: {
    name: '',
    email: '',
    hospitalType: '',
    longHospitalType: '',
    hospitalOrganization: '',
    hospitalRegion: '',
    altName: '',
    address: {
      street: '',
      village: '',
      subdistrict: '',
      regency: '',
      province: '',
    },
    postCode: '',
    telephoneNo: [],
    faxNo: '',
    logo: '',
    logoExt: 'PNG',
    distinctGender: true,
    ppkService: '',
    useLetterBackground: true,
  },
  apiUrl: process.env.VUE_APP_API_URL,
  serverType: process.env.VUE_APP_SERVER_TYPE,
  isProduction: process.env.NODE_ENV !== 'development',
  isProdServer:
    process.env.NODE_ENV !== 'development' &&
    !process.env.VUE_APP_API_URL.includes('neurovi'),
  version,
  passwordAuth: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%&?"_'-]{8,}$/,
  menus: [
    {
      title: 'Master Data',
      icon: 'chart-pie',
      slug: 'masterdata',
      allow: '1',
      children: [
        {
          title: 'Barang Farmasi',
          route: '/masterdata/drugs',
          allow: '1',
        },
        {
          title: 'Barang Rumah Tangga',
          route: '/masterdata/goods',
          allow: '2',
        },
        {
          title: 'Staf',
          route: '/masterdata/staff',
          allow: '3',
        },
        {
          title: 'Diagnosa',
          route: '/masterdata/diagnosa',
          allow: '4',
        },
        {
          title: 'Prosedur',
          route: '/masterdata/prosedur',
          allow: '5',
        },
        {
          title: 'Unit',
          route: '/masterdata/unit',
          allow: '6',
        },
        {
          title: 'Layanan',
          route: '/masterdata/layanan',
          allow: '7',
        },
        {
          title: 'Supplier',
          route: '/masterdata/supplier',
          allow: '8',
        },
        {
          title: 'Bahan Makanan',
          route: '/masterdata/bahan-makanan',
          allow: '9',
        },

        {
          title: 'Bed',
          route: '/masterdata/bed',
          allow: '10',
        },

        {
          title: 'Kamar',
          route: '/masterdata/room',
          allow: '11',
        },

        {
          title: 'Bangsal',
          route: '/masterdata/ward',
          allow: '12',
        },
        {
          title: 'Bank',
          route: '/masterdata/bank',
          allow: '13',
        },
        {
          title: 'Data Instansi Rekanan',
          route: '/masterdata/instansi-rekanan',
          allow: '14',
        },
        {
          title: 'Laboratorium',
          route: '/masterdata/laboratorium',
          allow: '15',
        },
        {
          title: 'Menu Makanan',
          route: '/masterdata/menu-makanan',
          allow: '16',
        },
        {
          title: 'Biaya Plafon',
          route: '/masterdata/biaya-plafon',
          allow: '17',
        },
      ],
    },
    {
      title: 'EMR',
      icon: 'notes-medical',
      slug: 'emr',
      allow: '2',
      children: [
        {
          title: 'EMR Patient',
          route: '/emr',
          allow: '1',
        },
        {
          title: 'Pengajuan Edit Data',
          route: '/emr/pengajuan-rubah-data',
          allow: '2',
        },
      ],
    },
    {
      title: 'Pendaftaran',
      icon: 'clipboard-list',
      slug: 'registration',
      allow: '3',
      children: [
        {
          title: 'Rawat Jalan',
          route: '/pendaftaran/pasien-baru',
          allow: '1',
        },
        {
          title: 'Test Tracer',
          route: '/pendaftaran/tracer-pasien-baru',
          allow: '1',
        },
        {
          title: 'IGD',
          route: '/pendaftaran/igd',
          allow: '2',
        },
        {
          title: 'Rawat Inap',
          route: '/pendaftaran/rawat-inap',
          allow: '3',
        },
        {
          title: 'Ketersediaan Bed',
          route: '/pendaftaran/ketersediaan-bed',
          allow: '4',
        },
        {
          title: 'Jadwal Dokter',
          route: '/pendaftaran/jadwal-dokter',
          allow: '5',
        },
        {
          title: 'Update Informasi Bed',
          route: '/pendaftaran/update-status-bed',
          allow: '6',
        },
      ],
    },
    {
      title: 'Pelayanan',
      icon: 'hand-holding-heart',
      slug: 'service',
      allow: '4',
      children: [
        {
          title: 'Rawat Inap',
          route: '/pelayanan/rawat-inap',
          allow: '1',
        },
        {
          title: 'Instalasi Gawat Darurat',
          route: '/pelayanan/poli-igd',
          allow: '2',
        },
        {
          title: 'Nurse Station',
          route: '/pelayanan/nurse-station',
          allow: '27',
        },
        {
          title: 'Unit VK',
          route: '/pelayanan/unit-vk',
          allow: '28',
        },
        {
          title: 'Hemodialisa',
          route: '/pelayanan/hemodialisa',
          allow: '4',
        },
        {
          title: 'Rehab Medik',
          route: '/pelayanan/rehab-medik',
          allow: '6',
        },
        {
          title: 'Klinik Kebidanan',
          route: '/pelayanan/klinik-kebidanan',
          allow: '7',
        },
        {
          title: 'Klinik Mata',
          route: '/pelayanan/klinik-mata',
          allow: '8',
        },
        {
          title: 'Klinik Bedah Umum',
          route: '/pelayanan/klinik-bedah-umum',
          allow: '9',
        },
        {
          title: 'Klinik Bedah Anak',
          route: '/pelayanan/klinik-bedah-anak',
          allow: '10',
        },
        {
          title: 'Klinik Bedah Mulut',
          route: '/pelayanan/klinik-bedah-mulut',
          allow: '11',
        },
        {
          title: 'Klinik Anak',
          route: '/pelayanan/klinik-anak',
          allow: '12',
        },
        {
          title: 'Klinik Gigi dan Mulut',
          route: '/pelayanan/klinik-gigi-dan-mulut',
          allow: '13',
        },
        {
          title: 'Klinik Orthodentis',
          route: '/pelayanan/klinik-orthodentis',
          allow: '14',
        },
        {
          title: 'Klinik Psikologi',
          route: '/pelayanan/klinik-psikologi',
          allow: '15',
        },
        {
          title: 'Klinik Jiwa',
          route: '/pelayanan/klinik-jiwa',
          allow: '16',
        },
        {
          title: 'Klinik Penyakit Dalam',
          route: '/pelayanan/klinik-penyakit-dalam',
          allow: '17',
        },
        {
          title: 'Klinik Syaraf',
          route: '/pelayanan/klinik-syaraf',
          allow: '18',
        },
        {
          title: 'Klinik Jantung',
          route: '/pelayanan/klinik-jantung',
          allow: '19',
        },
        {
          title: 'Klinik THT',
          route: '/pelayanan/klinik-tht',
          allow: '20',
        },
        {
          title: 'Klinik Orthopedi',
          route: '/pelayanan/klinik-orthopedi',
          allow: '21',
        },
        {
          title: 'Klinik Paru',
          route: '/pelayanan/klinik-paru',
          allow: '22',
        },
        {
          title: 'Klinik MCU',
          route: '/pelayanan/klinik-mcu',
          allow: '23',
        },
        {
          title: 'Klinik Geriatri',
          route: '/pelayanan/klinik-geriatri',
          allow: '24',
        },
        {
          title: 'Klinik Vaksinasi',
          route: '/pelayanan/klinik-vaksinasi',
          allow: '25',
        },
        {
          title: 'Klinik Gizi',
          route: '/pelayanan/klinik-gizi',
          allow: '26',
        },

        {
          title: 'Klinik TB Dots',
          route: '/pelayanan/klinik-tb-dots',
          allow: '29',
        },
        {
          title: 'Klinik Anestesi',
          route: '/pelayanan/klinik-anestesi',
          allow: '30',
        },
        {
          title: 'Klinik Urologi',
          route: '/pelayanan/klinik-urologi',
          allow: '31',
        },
        {
          title: 'Klinik Bayi Baru Lahir',
          route: '/pelayanan/klinik-bayi-baru-lahir',
          allow: '32',
        },
        {
          title: 'Klinik Berhenti Merokok',
          route: '/pelayanan/klinik-berhenti-merokok',
          allow: '33',
        },
        {
          title: 'Klinik Keluarga Sakinah',
          route: '/pelayanan/klinik-keluarga-sakinah',
          allow: '34',
        },
      ],
    },
    {
      title: 'Pelayanan Khusus',
      icon: 'wheelchair',
      slug: 'special',
      allow: '15',
      children: [
        {
          title: 'Pemulasaraan Jenazah',
          route: '/pelayanan-khusus/pemulasaraan-jenazah',
          allow: '1',
        },
        {
          title: 'Ambulance',
          route: '/pelayanan-khusus/ambulance',
          allow: '2',
        },
      ],
    },
    {
      title: 'Penunjang Medis',
      icon: 'plus-square',
      slug: 'medical',
      allow: '14',
      children: [
        {
          title: 'Fisioterapi',
          route: '/penunjang-medis/fisioterapi',
          allow: '1',
        },
        {
          title: 'Terapi Wicara',
          route: '/penunjang-medis/terapi-wicara',
          allow: '2',
        },
        {
          title: 'Terapi Okupasi',
          route: '/penunjang-medis/terapi-okupasi',
          allow: '3',
        },
      ],
    },
    {
      title: 'IBS',
      icon: 'heartbeat',
      slug: 'ibs',
      allow: '5',
      children: [
        {
          title: 'Pelayanan Operasi',
          route: '/ibs/pelayanan-operasi',
          allow: '1',
        },
        {
          title: 'Jadwal Operasi',
          route: '/ibs/jadwal-operasi',
          allow: '2',
        },
        {
          title: 'Penundaan Operasi',
          route: '/ibs/penundaan-operasi',
          allow: '2',
        },
      ],
    },
    {
      title: 'Farmasi',
      icon: 'prescription-bottle',
      slug: 'pharmacy',
      allow: '6',
      children: [
        {
          title: 'Rawat Inap',
          route: '/farmasi/rawat-inap',
          allow: '1',
        },
        {
          title: 'Rawat Jalan',
          route: '/farmasi/rawat-jalan',
          allow: '2',
        },
        {
          title: 'IGD',
          route: '/farmasi/igd',
          allow: '3',
        },
        {
          title: 'IBS',
          route: '/farmasi/ibs',
          allow: '4',
        },
        {
          title: 'Hemodialisa',
          route: '/farmasi/hd',
          allow: '5',
        },
      ],
    },
    {
      title: 'Reporting',
      icon: 'clipboard-list',
      slug: 'reporting',
      allow: '7',
      children: [
        {
          title: 'Dashboard Manajemen',
          route: '/reporting/dashboard-management',
          allow: '7',
        },
        {
          title: 'Data Antrian Online',
          route: '/reporting/data-antrian-online',
          allow: '7',
        },
        {
          title: 'Data Pasien TB',
          route: '/reporting/reporting-sitb',
          allow: '4',
        },
        {
          title: 'Reporting Farmasi',
          route: '/reporting/reporting-farmasi',
          allow: '1',
        },
        {
          title: 'Reporting Kunjungan',
          route: '/reporting/reporting-kunjungan-pasien',
          allow: '2',
        },
        {
          title: 'Laporan Sensus Harian',
          route: '/reporting/laporan-sensus-harian',
          allow: '3',
        },
        {
          title: 'Laporan Screening TBC',
          route: '/reporting/reporting-tb',
          allow: '5',
        },
        {
          title: 'Penyakit Tidak Menular',
          route: '/reporting/penyakit-tidak-menular',
          allow: '6',
        },
        {
          title: 'Laporan Rekapitulasi Rumah Sakit',
          route: '/reporting/reporting-recap-hospital',
          allow: '7',
        },
        {
          title: 'Data Bulanan Laboratorium',
          route: '/reporting/laporan-bulanan-laboratorium',
          allow: '3',
        },
        {
          title: 'Response Time Pelayanan RJ',
          route: '/reporting/response-pelayanan-rj',
          allow: '3',
        },
      ],
    },
    {
      title: 'Radiologi',
      icon: 'x-ray',
      route: '/radiologi',
      slug: 'radiology',
      allow: '8',
    },
    {
      title: 'Laboratorium',
      icon: 'vial',
      slug: 'laboratorium',
      allow: '9',
      children: [
        {
          title: 'Laboratorium',
          route: '/laboratorium/klinik',
          allow: '1',
        },
        {
          title: 'Patologi Anatomi',
          route: '/laboratorium/patologi',
          allow: '2',
        },
        {
          title: 'Transfusi Darah ',
          route: '/laboratorium/transfusi-darah',
          allow: '3',
        },
      ],
    },
    {
      title: 'Keuangan',
      icon: 'file-invoice-dollar',
      slug: 'billing',
      allow: '10',
      children: [
        {
          title: 'Pembayaran',
          route: '/keuangan/billing',
          allow: '1',
        },
        {
          title: 'Akuntansi',
          route: '/keuangan/akuntansi',
          allow: '2',
          notReleased: true,
        },
        {
          title: 'Keuangan',
          route: '/keuangan',
          allow: '3',
          notReleased: true,
        },
        {
          title: 'Settings',
          route: '/keuangan/settings',
          allow: '4',
          notReleased: true,
        },
        {
          title: 'Donasi',
          route: '/keuangan/donasi',
          allow: '4',
          notReleased: true,
        },
      ],
    },
    {
      title: 'Inventaris',
      icon: 'box-open',
      slug: 'inventory',
      allow: '11',
      children: [
        {
          title: 'Pemesanan Barang',
          route: '/inventaris/pemesanan',
          rule: 'isWarehouse',
          allow: '1',
        },
        {
          title: 'Penerimaan Barang',
          route: '/inventaris/penerimaan',
          rule: 'isWarehouse',
          allow: '2',
        },
        {
          title: 'Informasi Stok',
          route: '/inventaris/stok',
          allow: '3',
        },
        {
          title: 'Permintaan Barang Gudang',
          route: '/inventaris/permintaan',
          allow: '4',
          notification: 0,
        },
        {
          title: 'Mutasi Barang',
          route: '/inventaris/mutasi',
          allow: '6',
          notification: 0,
        },
        {
          title: 'Stok Opname',
          route: '/inventaris/stok-opname',
          allow: '7',
          notification: 0,
        },
        {
          title: 'Peminjaman dan Pengembalian Barang',
          route: '/inventaris/peminjaman',
          rule: 'isPharmacyWarehouse',
          allow: '10',
        },
        {
          title: 'Rencana Pengadaaan Rumah Tangga',
          route: '/inventaris/rencana-pengadaan/rumah-tangga',
          rule: 'isWarehouseNotPharmacy',
          allow: '8',
        },
        {
          title: 'Rencana Pengadaaan Farmasi',
          route: '/inventaris/rencana-pengadaan/farmasi',
          rule: 'isPharmacyWarehouse',
          allow: '9',
        },
        {
          title: 'Pemakaian Barang Unit',
          route: '/inventaris/pemakaian-barang-unit',
          allow: '11',
        },
      ],
    },
    {
      title: 'Gizi',
      icon: 'concierge-bell',
      slug: 'gizi',
      allow: '12',
      children: [
        {
          title: 'Order Makanan Pasien',
          route: '/gizi/order-makanan-pasien',
          allow: '1',
        },
        {
          title: 'Rekap Order Makanan',
          route: '/gizi/rekap-order-makanan',
          allow: '2',
        },
        {
          title: 'Penggunaan Bahan Gizi',
          route: '/gizi/penggunaan-bahan-gizi',
          allow: '3',
        },
        {
          title: 'Konsultasi Gizi Rawat Inap',
          route: '/gizi/konsultasi-gizi-rawat-inap',
          allow: '4',
        },
      ],
    },
    {
      title: 'Case Mix',
      icon: 'shield-alt',
      slug: 'casemix',
      allow: '13',
      children: [
        {
          title: 'Berita Acara Klaim BPJS',
          route: '/casemix/berita-klaim-bpjs',
          allow: '1',
        },
        {
          title: 'Pemberkasan Klaim BPJS ',
          route: '/casemix/pemberkasan-klaim-bpjs',
          allow: '2',
        },
      ],
    },
  ],
};

export default constant;
