import Constant from '@/const';

const axios = require('axios');

const state = {
  tabs: 0,
  filter: {
    search: '',
    patientType: '',
    printStatus: '',
    fingerprintStatus: '',
    sepStatus: '',
    date: '',
    doctor: '',
    poly: '',
    polyPagination: {
      descending: true,
      page: 1,
      rowsPerPage: 5,
      totalItems: 0,
      finished: 0,
      undefined: 0,
    },
  },
  items: {
    patientRegistration: {
      isLoading: false,
      data: [],
    },
    doctorList: {
      isLoading: false,
      data: [],
    },
    unitList: {
      isLoading: false,
      data: [],
    },
  },
  resource: {
    patientType: [
      { text: 'Semua pasien', value: '' },
      { text: 'Pasien baru', value: 'new' },
      { text: 'Pasien lama', value: 'old' },
    ],
    printStatus: [
      { value: '', text: 'Semua' },
      { value: false, text: 'Belum dicetak' },
      { value: true, text: 'Sudah dicetak' },
    ],
    fingerprint: [
      { value: '', text: 'Semua' },
      { value: false, text: 'Belum' },
      { value: true, text: 'Sudah' },
    ],
    sepStatus: [
      { value: '', text: 'Semua' },
      { value: false, text: 'Belum' },
      { value: true, text: 'Sudah' },
    ],
  },
};

const getters = {
  getTabs: (state) => {
    return state.tabs;
  },
  getFilter: (state) => state.filter,
  getResource: (state) => state.resource,
  getItems: (state) => state.items,
};

const mutations = {
  setTabs(state, payload) {
    state.tabs = payload;
  },
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },
};

const actions = {
  async resolveGetPatientRegistration(
    { commit, state },
    { getPaymentTypeString, parseDateToTime, formatDate, calculateAge },
  ) {
    commit('setItems', {
      label: 'patientRegistration',
      value: [...state.items.patientRegistration.data],
      isLoading: true,
    });
    const url = Constant.apiUrl.concat(
      `/patient/queue/all?`,
      `page=${state.filter.polyPagination.page}`,
      `&itemCount=${state.filter.polyPagination.rowsPerPage}`,
      `&search=${state.filter.search || ''}`,
      `&date=${state.filter.date}`,
      `&is_printed=${state.filter.printStatus}`,
      `&patient_type=${state.filter.patientType}`,
      `&is_finger_print=${state.filter.fingerprintStatus}`,
      `&is_sep=${state.filter.sepStatus}`,
      state.filter.doctor ? `&doctor=${state.filter.doctor.id}` : '',
      state.filter.unit ? `&poly=${state.filter.unit.id}` : '',
    );
    try {
      const response = await axios.get(url);
      state.filter.polyPagination.totalItems = response.data.count;
      state.filter.polyPagination.finished = response.data.countFinish;
      state.filter.polyPagination.unfinished = response.data.countUnfinish;

      const responseMapper = response.data.data
        .filter((arr) => arr)
        .map((patient, index) => ({
          isAdvanced: calculateAge(patient.patientData.birth_date) >= 17,
          no:
            index +
            1 +
            (state.filter.polyPagination.page - 1) *
              (state.filter.polyPagination.rowsPerPage || 1),
          isBPJS: patient.payment.payment_type === 4,
          fingerprintStatus: patient.patientData.bpjs.finger_print_status,
          isLoadingPatient: false,
          isExpanded: false,
          isHovering: false,
          onHover: false,
          id: patient.patientData.id.toString(),
          id_queue: patient.patientQueue.id.toString(),
          no_queue: patient.patientQueue.queue_no.toString(),
          id_ref: patient.patientQueue.id,
          id_registration: patient.patientQueue.id_registration,
          queue_code: patient.patientQueue.queue_code,
          no_reg: patient.patientQueue.registration_no.toString(),
          poly_no: patient.patientQueue.poly_no.toString(),
          doctor_queue_no: patient.patientQueue.doctor_queue_no.toString(),
          support_service_no: patient.patientQueue.support_service_no,
          name: patient.patientData.name,
          refference: patient.isRefference,
          isPriority: patient.patientData.priority_card
            ? patient.patientData.priority_card.type || 0
            : '',
          no_rm: patient.patientData.rm_no,
          regist_number: patient.patientQueue.registration_number,
          start_admision: patient.patientQueue.start_admision
            ? patient.patientQueue.start_admision
            : '',
          hasCheckIn: patient.patientQueue.hasCheckIn,
          cancelCheckIn: patient.patientQueue.cancelCheckIn,
          birth_date: patient.patientData.birth_date,
          payment: getPaymentTypeString(
            patient.payment.payment_type,
            patient.payment.detail,
            patient.patientData.bpjs,
            patient.payment,
          ),
          service_type: patient.service_type,
          poli_type:
            typeof patient.poly.id_poly !== `undefined`
              ? patient.poly.id_poly
              : '',
          support_type:
            typeof patient.support_service.id_poly !== `undefined`
              ? patient.support_service.id_poly
              : '',
          isMJKN: patient.patientQueue.registered_from === 'mjkn',
          meta: {
            isPrinted:
              patient.patientQueue.count_queue_printed > 0 &&
              patient.patientQueue.count_barcode_printed > 0,
            isOutpatient: true,
            registerTime: patient.patientQueue.registerTime,
            regDate: patient.patientQueue.registered_at,
            phone: patient.patientData.phone_no || '',
            identityNumber: patient.patientData.identity
              ? patient.patientData.identity.number
              : '',
            address: patient.patientData.address,
            reg_time: parseDateToTime(patient.patientQueue.registered_at),
            gender: patient.patientData.gender ? 'Laki-laki' : 'Perempuan',
            statusOldNew:
              patient.patientData.patient_type === 'new' ? 'Baru' : 'Lama',
            doctor: patient.poly.doctor,
            doctor_bpjs_code: patient.poly.doctor_bpjs_code,
            formateddate: formatDate(patient.patientQueue.registered_at),
            date: patient.patientQueue.registered_at,
            age: calculateAge(patient.patientData.birth_date),
            polyNumber: patient.poly.no_poly,
            dob: formatDate(patient.patientData.birth_date),
            action_status: patient.patientQueue.action_status,
            assesment_doctor_status:
              patient.patientQueue.assesment_doctor_status,
            assesment_nurse_status: patient.patientQueue.assesment_nurse_status,
            assesment_therapy_status:
              patient.patientQueue.assesment_therapy_status,
            finishReg: patient.patientQueue.finishReg,
            doctorStart: patient.poly.doctor_start,
            // bpjs: {
            //   // TODO: ask SEP number to Back-end
            //   sep_no: '',
            //   sep_date: patient.patientData.bpjs.sep_data,
            //   no:
            //     patient.patientData.bpjs.bpjs_no === '' ? '' : patient.patientData.bpjs.bpjs_no,
            //   phone_no:
            //     typeof patient.patientData.bpjs.phone !== `undefined`
            //       ? patient.patientData.bpjs.phone
            //       : '',
            //   spesialis:
            //     typeof patient.poly.id_poly !== `undefined` ? patient.poly.id_poly : '',
            //   diagnosis:
            //     typeof patient.patientData.bpjs.diagnosis !== `undefined`
            //       ? patient.patientData.bpjs.diagnosis.text
            //       : '',
            //   note:
            //     typeof patient.patientData.bpjs.note !== `undefined`
            //       ? patient.patientData.bpjs.note
            //       : '',
            //   // TODO: ask BPJS 'Peserta' to Back-end
            //   user: '',
            //   // TODO: ask BPJS 'COB' to Back-end
            //   cob: '',
            //   // TODO: change when Rawat Inap deployed
            //   care_type: 'Rawat Jalan',
            //   care_class:
            //     typeof patient.patientData.bpjs.health_facility_level !== `undefined`
            //       ? patient.patientData.bpjs.health_facility_level
            //       : '',
            //   // TODO: ask if guarantor is PIC or orther
            //   guarantor: '',

            // },
            bpjs: {
              detail:
                patient.patientData.bpjs.health_facility_level === null
                  ? ''
                  : patient.patientData.bpjs.health_facility_level,
              // eslint-disable-next-line no-nested-ternary
              no: (() => {
                if (
                  patient.payment &&
                  patient.payment.detail &&
                  patient.payment.detail.data
                ) {
                  return patient.payment.detail.data;
                }
                return patient.patientData.bpjs.bpjs_no === null
                  ? ''
                  : patient.patientData.bpjs.bpjs_no;
              })(),
              no_sep:
                patient.patientData.bpjs.no_sep === null
                  ? ''
                  : patient.patientData.bpjs.no_sep,
              phone:
                patient.patientData.bpjs.phone || patient.patientData.phone_no,
              polyCode: patient.poly.bpjs_poly_code,
              referenceNo: patient.patientData.bpjs.reference_no,
            },
            status: patient.patientQueue.status,
          },
        }));
      commit('setItems', {
        label: 'patientRegistration',
        value: responseMapper,
        isLoading: false,
      });
      return response.data.length;
    } catch (err) {
      state.filter.polyPagination.totalItems = 0;
      return commit('setItems', {
        label: 'patientRegistration',
        value: [],
        isLoading: false,
      });
    }
  },
  async resolveGetDoctorList({ commit, state }) {
    commit('setItems', {
      label: 'doctorList',
      value: [...state.items.doctorList.data],
      isLoading: true,
    });
    try {
      const response = await axios.get(
        Constant.apiUrl.concat(
          '/staff/master?&id_role=["dokter","psikologi","fisioterapi", "terapi wicara", "ahli gizi"]',
        ),
      );
      const responseMapper = response.data.data.map((doctor) => {
        return {
          id: doctor._id.toString(),
          name: doctor.detail[0].name,
        };
      });
      return commit('setItems', {
        label: 'doctorList',
        value: responseMapper,
        isLoading: false,
      });
    } catch (error) {
      return commit('setItems', {
        label: 'doctorList',
        value: [],
        isLoading: false,
      });
    }
  },
  async resolveGetUnitList({ commit, state }) {
    commit('setItems', {
      label: 'unitList',
      value: [...state.items.unitList.data],
      isLoading: true,
    });
    try {
      const response = await axios.get(
        Constant.apiUrl.concat(
          '/masterdata/unit/category/Instalasi Rawat Jalan',
        ),
      );
      const responseMapper = response.data.data.map((clinic) => {
        return {
          text: clinic.detail.name,
          id: clinic._id,
          unitNumber: clinic.unit_number,
        };
      });
      return commit('setItems', {
        label: 'unitList',
        value: responseMapper,
        isLoading: false,
      });
    } catch (error) {
      return commit('setItems', {
        label: 'unitList',
        value: [],
        isLoading: false,
      });
    }
  },
};

export default { state, getters, mutations, actions };
