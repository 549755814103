import Constant from '@/const';
import moment from 'moment';

const axios = require('axios');

// function checkStringBlank(myString) {
//   return /^\s*$/.test(myString);
// }

export default {
  namespaced: true,
  state: {
    isFirstTime: false,
    adultAssessment: {
      medicalDiagnose: null,
      diagnosePlan: null,
    },
    kidAssessment: {
      medicalDiagnose: null,
      behaviour: null,
      sensomotor: null,
      cognitive: null,
      occupationArea: null,
    },
  },
  getters: {
    adultAssessment: (state) => state.adultAssessment,
    kidAssessment: (state) => state.kidAssessment,
  },
  mutations: {
    changeFirstTime(state, payload) {
      state.isFirstTime = payload;
    },
    saveAssessment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    removeAssessment(state) {
      state.adultAssessment = {
        medicalDiagnose: null,
        diagnosePlan: null,
      };
      state.kidAssessment = {
        medicalDiagnose: null,
        behaviour: null,
        sensomotor: null,
        cognitive: null,
        occupationArea: null,
      };
    },
  },
  actions: {
    postAdultAssessment({ getters, rootGetters }) {
      const payload = {
        isRefference: rootGetters.selectedPatient.isReferral || false,
        detail_poly: [
          {
            medical_diagnose:
              getters.adultAssessment.medicalDiagnose.medical_diagnose,
            physical_checkup:
              getters.adultAssessment.medicalDiagnose.physical_checkup,
            basic_inspection:
              getters.adultAssessment.medicalDiagnose.basic_inspection,
            occupational_performance:
              getters.adultAssessment.medicalDiagnose.occupational_performance,
            occupational_performance_area:
              getters.adultAssessment.medicalDiagnose
                .occupational_performance_area,
            spesific_checkup: {
              detail:
                getters.adultAssessment.medicalDiagnose.spesific_checkup.detail,
              medical_diagnosis:
                getters.adultAssessment.diagnosePlan.medicalDiagnose.map(
                  (diagnose) => {
                    return diagnose.icd && diagnose.icd.id
                      ? {
                          detail: diagnose.detail,
                          diagnose_id: diagnose.icd.id,
                          text: diagnose.icd.text,
                          value: diagnose.icd.value,
                        }
                      : {
                          detail: diagnose.detail,
                        };
                  },
                ),
              diagnose: getters.adultAssessment.diagnosePlan.diagnose.map(
                (diagnose) => {
                  return diagnose.icd && diagnose.icd.id
                    ? {
                        detail: diagnose.detail,
                        diagnose_id: diagnose.icd.id,
                        text: diagnose.icd.text,
                        value: diagnose.icd.value,
                      }
                    : {
                        detail: diagnose.detail,
                      };
                },
              ),
              plan: getters.adultAssessment.diagnosePlan.plan,
            },
            timestamps: {
              created_by: rootGetters.userLoggedIn.id,
            },
          },
        ],
        detail_intervention: [
          {
            intervention_detail:
              getters.adultAssessment.diagnosePlan.intervention_detail
                .filter((item) => item.follow_up[0].detail)
                .reverse()
                .map((arr) => {
                  return {
                    intervensi: arr.intervensi,
                    area: arr.area,
                    evaluation: arr.evaluation,
                    follow_up: arr.follow_up.map((arr) =>
                      arr.icd && arr.icd.id
                        ? {
                            id: arr.icd.id,
                            text: arr.icd.text,
                            value: arr.icd.value,
                            detail: arr.detail,
                          }
                        : {
                            detail: arr.detail,
                          },
                    ),
                    timestamps: {
                      created_by:
                        arr.created_by_id || rootGetters.userLoggedIn.id,
                    },
                  };
                }),
            timestamps: {
              created_by:
                getters.adultAssessment.diagnosePlan.intervention_detail[0]
                  .created_by_id || rootGetters.userLoggedIn.id,
            },
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        payload.detail_poly[0] = {
          ...payload.detail_poly[0],
          timestamps: {
            created_by:
              getters.adultAssessment.diagnosePlan.created_by_id ||
              rootGetters.userLoggedIn.id,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
        payload.detail_intervention[0].intervention_detail[
          payload.detail_intervention[0].intervention_detail.length - 1
        ] = {
          ...payload.detail_intervention[0].intervention_detail[
            payload.detail_intervention[0].intervention_detail.length - 1
          ],
          timestamps: {
            created_by:
              payload.detail_intervention[0].intervention_detail[
                payload.detail_intervention[0].intervention_detail.length - 1
              ].created_by_id || rootGetters.userLoggedIn.id,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      console.log(payload);
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/86/2/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/1'),
        {
          ...payload,
          detail_intervention: [
            {
              intervention_detail: [
                {
                  ...payload.detail_intervention[0].intervention_detail[0],
                },
              ],
              timestamps: payload.detail_intervention[0].timestamps,
            },
          ],
        },
      );
    },
    postKidAssessment({ getters, rootGetters }) {
      const payload = {
        isRefference: rootGetters.selectedPatient.isMedicalSupport || false,
        detail_poly: [
          {
            medical_diagnose: getters.kidAssessment.medicalDiagnose,
            behavior: getters.kidAssessment.behaviour,
            sensorimotor_capatibilities: getters.kidAssessment.sensomotor,
            cognitive_capabilities: getters.kidAssessment.cognitive,
            occupational_performance_area: getters.kidAssessment.occupationArea,
            medical_diagnosis:
              getters.adultAssessment.diagnosePlan.medicalDiagnose.map(
                (diagnose) => {
                  return diagnose.icd && diagnose.icd.id
                    ? {
                        detail: diagnose.detail,
                        diagnose_id: diagnose.icd.id,
                        text: diagnose.icd.text,
                        value: diagnose.icd.value,
                      }
                    : {
                        detail: diagnose.detail,
                      };
                },
              ),
            diagnose: getters.adultAssessment.diagnosePlan.diagnose.map(
              (diagnose) => {
                return diagnose.icd && diagnose.icd.id
                  ? {
                      detail: diagnose.detail,
                      diagnose_id: diagnose.icd.id,
                      text: diagnose.icd.text,
                      value: diagnose.icd.value,
                    }
                  : {
                      detail: diagnose.detail,
                    };
              },
            ),
            plan: getters.adultAssessment.diagnosePlan.plan,
            timestamps: {
              created_by: rootGetters.userLoggedIn.id,
            },
          },
        ],
        detail_intervention: [
          {
            intervention_detail:
              getters.adultAssessment.diagnosePlan.intervention_detail
                .filter((item) => item.follow_up[0].detail)
                .reverse()
                .map((arr) => {
                  return {
                    intervensi: arr.intervensi,
                    area: arr.area,
                    evaluation: arr.evaluation,
                    follow_up: arr.follow_up.map((arr) =>
                      arr.icd && arr.icd.id
                        ? {
                            id: arr.icd.id,
                            text: arr.icd.text,
                            value: arr.icd.value,
                            detail: arr.detail,
                          }
                        : {
                            detail: arr.detail,
                          },
                    ),
                    timestamps: {
                      created_by:
                        arr.created_by_id || rootGetters.userLoggedIn.id,
                    },
                  };
                }),
            timestamps: {
              created_by:
                getters.adultAssessment.diagnosePlan.intervention_detail[0]
                  .created_by_id || rootGetters.userLoggedIn.id,
            },
          },
        ],
      };

      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        payload.detail_poly[0] = {
          ...payload.detail_poly[0],
          timestamps: {
            created_by:
              getters.adultAssessment.diagnosePlan.created_by_id ||
              rootGetters.userLoggedIn.id,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
        payload.detail_intervention[0].intervention_detail[
          payload.detail_intervention[0].intervention_detail.length - 1
        ] = {
          ...payload.detail_intervention[0].intervention_detail[
            payload.detail_intervention[0].intervention_detail.length - 1
          ],
          timestamps: {
            created_by:
              payload.detail_intervention[0].intervention_detail[
                payload.detail_intervention[0].intervention_detail.length - 1
              ].created_by_id || rootGetters.userLoggedIn.id,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/86/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/1'),
        {
          ...payload,
          detail_intervention: [
            {
              intervention_detail: [
                {
                  ...payload.detail_intervention[0].intervention_detail[0],
                },
              ],
              timestamps: payload.detail_intervention[0].timestamps,
            },
          ],
        },
      );
    },
  },
};
